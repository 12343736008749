import { useContext, useState } from 'react'
import SocialButtons from './SocialButtons'
import TextInput from './TextInput'
import { HOSTNAME } from '../../mdc_modules/mdcApiFetch'
import { AuthContext } from '../../providers/AuthContextProvider'
import InlineLoader from '../InlineLoader'
import EyeIcon from '../../icons/EyeIcon'

export default function Login({ onForgotPassword, onSuccess }) {
  const { reload } = useContext(AuthContext)
  const [formData, setFormData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: null, password: null })
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value.trim(),
    }))
    setErrors((prev) => ({ ...prev, [event.target.name]: null, submissionError: null }))
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault()
    try {
      let hasErrors = false
      setErrors({})
      if (!formData.email) {
        setErrors((prev) => ({ ...prev, email: 'Email is required' }))
        hasErrors = true
      }
      if (!formData.password) {
        setErrors((prev) => ({ ...prev, password: 'Password is required' }))
        hasErrors = true
      }
      if (hasErrors) {
        return
      }

      const response = await fetch(`${HOSTNAME}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({
          action: 'login',
          email: formData['email'],
          password: formData['password'],
        }),
      })
      if (response.ok) {
        const { success, message } = await response.json()
        if (success) {
          await reload()
          onSuccess()
        } else {
          setErrors({ submissionError: message })
        }
      } else {
        setErrors({
          submissionError: 'Error submitting request. Please try again or contact support.',
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handelForgotPasswordClick = (event) => {
    event.preventDefault()
    onForgotPassword()
  }

  return (
    <>
      <form className="sb-sign-in-pop-up__form-layout" onSubmit={handleSubmit}>
        <TextInput
          name="email"
          label="Email"
          type="email"
          maxLength={50}
          value={formData.email}
          error={errors.email}
          onChange={handleChange}
        />

        <TextInput
          name="password"
          label="Password"
          type="password"
          maxLength={50}
          value={formData.password}
          error={errors.password}
          leftIcon={<EyeIcon />}
          onChange={handleChange}
        />

        <label
          style={{
            textAlign: 'right',
            textTransform: 'uppercase',
            fontSize: '11px',
            lineHeight: '11px',
            letterSpacing: '0.04rem',
            textDecoration: 'underline',
            padding: '8px 0',
          }}
        >
          <a onClick={handelForgotPasswordClick}>Forgot Password?</a>
        </label>

        <button
          xmlns="http://www.w3.org/1999/xhtml"
          type="submit"
          className="sb-button sb-button--primary"
        >
          {loading ? <InlineLoader /> : 'Sign In'}
        </button>

        <div
          style={{
            color: '#BB2A33',
            textAlign: 'center',
            fontSize: '12px',
            lineHeight: '15.6px',
            transition: 'height .5s',
            height: errors.submissionError ? 'auto' : 0,
          }}
        >
          {errors.submissionError}
        </div>
      </form>
      {/* <SocialButtons /> */}
    </>
  )
}
